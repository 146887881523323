import { bindable, inject }                               from 'aurelia-framework';
import { AppContainer }                                   from 'resources/services/app-container';
import { BaseFormViewModel }                              from 'base-form-view-model';
import { DialogService }                                  from 'aurelia-dialog';
import { FormSchema }                                     from 'modules/extraordinary-inspections/awaiting-execution/reassign-inspector/form-schema';
import { ExtraordinaryInspectionsAwaitingExecutionRepository } from 'modules/extraordinary-inspections/awaiting-execution/services/repository';

@inject(AppContainer, DialogService, FormSchema, ExtraordinaryInspectionsAwaitingExecutionRepository)
export class ReassignInspectorExtraordinaryInspections extends BaseFormViewModel {

    @bindable headerTitle = 'form.title.reassign-inspector-extraordinary-inspections';
    @bindable formId      = 'extraordinary-inspections-awaiting-execution-reassign-inspector-form';

    alert  = {};
    model  = {};
    schema = {};

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogService
     * @param formSchema
     * @param repository
     */
    constructor(appContainer, dialogService, formSchema, repository) {
        super(appContainer);

        this.dialogService = dialogService;
        this.formSchema    = formSchema;
        this.repository    = repository;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/extraordinary-inspections/awaiting-execution/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'extraordinary-inspections.awaiting-execution.manage',
            'extraordinary-inspections.awaiting-execution.edit',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params, navigationInstruction) {
        super.activate(navigationInstruction);

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.fetchData(params).then(() => this.schema = this.formSchema.schema(this));
    }

    /**
     * Handles attached event
     */
    attached() {
        super.attached();

        this.headerSubtitle = this.model.code;
    }

    /**
     * Fetches data from server
     *
     * @param {id}
     *
     * @returns {Promise.<*>|Promise}
     */
    fetchData({ id }) {
        return this.repository.find(id).then((response) => {
            this.initialModel.assign(response);
            this.model.assign(response);
        });
    }

    /**
     * Submits view form
     */
    submit() {
        return this.repository
            .reassign_inspector(this.model.id, this.model)
            .then(response => this.afterSubmitCallback(response))
            .then(response => response.status === true ? this.appContainer.router.navigateToRoute('extraordinary-inspections.awaiting-execution.index') : false);
    }

    /**
     * Handles the submit form response
     *
     * @param response
     */
    afterSubmitCallback(response) {
        if (response.status === true) {
            this.appContainer.notifier.successNotice(response.message);

            this.initialModel.assign(this.model);
        } else {
            this.alert = this.alertMessage(response.status, response.message, response.errors);
        }

        return response;
    }

}
