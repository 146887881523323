import { bindable, bindingMode, inject }                     from 'aurelia-framework';
import { AppContainer }                                      from 'resources/services/app-container';
import { BaseListViewModel }                                 from 'base-list-view-model';
import { ConfirmProcedureCancellationDialog }                from 'resources/elements/html-elements/dialogs/confirm-procedure-cancellation-dialog';
import { ConfirmProcedurePaymentDialog }                     from 'resources/elements/html-elements/dialogs/confirm-procedure-payment-dialog';
import { DialogService }                                     from 'aurelia-dialog';
import { Downloader }                                        from 'resources/services/downloader';
import { InfoDialog }                                        from 'resources/elements/html-elements/dialogs/info-dialog';
import { ExtraordinaryInspectionPaymentsRepository }         from 'modules/extraordinary-inspections/awaiting-payment/payments/services/repository';
import { ExtraordinaryInspectionsAwaitingPaymentRepository } from 'modules/extraordinary-inspections/awaiting-payment/requests/services/repository';
import { ProcedureStatus }                                   from 'modules/procedures/models/procedure-status';
import moment                                                from 'moment';

@inject(AppContainer, DialogService, Downloader, ExtraordinaryInspectionsAwaitingPaymentRepository, ExtraordinaryInspectionPaymentsRepository)
export class ListExtraordinaryInspectionsAwaitingPayment extends BaseListViewModel {

    @bindable listingId;
    @bindable filterModel;
    @bindable repository;
    @bindable datatable;
    @bindable masterPage;
    @bindable({defaultBindingMode: bindingMode.twoWay}) filterFormIsDirty;

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogService
     * @param downloader
     * @param repository
     * @param paymentsRepository
     */
    constructor(appContainer, dialogService, downloader, repository, paymentsRepository) {
        super(appContainer);

        this.dialogService      = dialogService;
        this.downloader         = downloader;
        this.repository         = repository;
        this.paymentsRepository = paymentsRepository;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'extraordinary-inspections.awaiting-payment.manage',
            'extraordinary-inspections.awaiting-payment.view',
        ]);
    }

    /**
     * Handles bind event
     */
    bind() {
        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            resource:        'resource.extraordinary-inspections.extraordinary-inspection',
            repository:      this.repository,
            show:            null,
            edit:            null,
            destroy:         null,
            destroySelected: false,
            actions:         [
                {
                    icon:    'icon-cross2',
                    tooltip: 'form.button.cancel',
                    action:  (row) => this.cancelRequest(row),
                    visible: (row) => this.appContainer.authenticatedUser.can(['extraordinary-inspections.awaiting-payment.manage', 'extraordinary-inspections.awaiting-payment.delete'])
                        && row.status.id === ProcedureStatus.AWAITING_PAYMENT,
                },
            ],
            options:         [
                {
                    label:  'form.button.export-to-excel',
                    icon:   'icon-file-excel',
                    action: () => this.exportListing('inspecoes_extraordinarias'),
                },
            ],
            buttons:         [
                {
                    label:   'form.button.new-payment',
                    icon:    'icon-plus3',
                    color:   'teal',
                    visible: this.appContainer.authenticatedUser.can(['extraordinary-inspections.awaiting-payment.manage', 'extraordinary-inspections.awaiting-payment.create']),
                    action:  () => this.requestSelectedRecords(),
                },
            ],
            selectable:      (row) => ! row.has_payments,
            sorting:         {
                column:    5,
                direction: 'asc',
            },
            columns:         [
                {
                    data:   'code',
                    name:   'lifts.code',
                    title:  'form.field.lift-number',
                    type:   'link',
                    action: (row) => this.appContainer.router.navigateToRoute('lifts.lifts.view', { id: row.lift_id }),
                },
                {
                    data:  'address',
                    name:  'lifts.address',
                    title: 'form.field.address',
                },
                {
                    data:  'parish',
                    name:  'parishes.name',
                    title: 'form.field.parish',
                },
                {
                    data:  'maintenance_company',
                    name:  'maintenance_company_data.name',
                    title: 'form.field.emie',
                },
                {
                    data:  'owner',
                    name:  'owner_data.name',
                    title: 'form.field.owner',
                },
                {
                    data:            'deadline',
                    name:            'procedures.deadline',
                    title:           'form.field.payment-deadline',
                    type:            'label',
                    color:           (row) => this.processDeadlineColor(row.deadline),
                    valueConverters: [
                        { name: 'dateFormat' },
                    ],
                },
                {
                    data:            'certificate_deadline',
                    name:            'lift_certificates.deadline',
                    title:           'form.field.certificate-deadline',
                    type:            'label',
                    color:           (row) => this.processDeadlineColor(row.certificate_deadline),
                    valueConverters: [
                        { name: 'dateFormat' },
                    ],
                },
                {
                    data:       'days_remaining',
                    name:       'days_remaining',
                    title:      'form.field.out-of-schedule',
                    type:       'processed',
                    processor:  (row) => row.days_remaining < 0 ? `${Math.abs(row.days_remaining)} ${this.appContainer.i18n.tr('text.day(s)')}` : '-',
                    searchable: false,
                },
            ],
        };
    }

    /**
     * Returns the correct stoplight based on the current date and deadline
     *
     * @param date
     *
     * @return {string}
     */
    processDeadlineColor(date) {
        let deadline        = moment(date);
        let closeToDeadline = moment(date).subtract(2, 'weeks');
        let now             = moment();

        if (now.isAfter(deadline, 'day')) {
            return 'badge-danger';
        } else if (now.isAfter(closeToDeadline, 'day')) {
            return 'badge-warning';
        }

        return 'badge-success';
    }

    /**
     * Handles the `new payment` action button
     *
     * @returns {*}
     */
    requestSelectedRecords() {
        this.alert = null;

        let selectedRows = this.datatable.instance.selectedRows;

        if ( ! selectedRows.length) {
            return this.dialogService.open({
                viewModel: InfoDialog,
                model:     {
                    body:  this.appContainer.i18n.tr('message.select-at-least-one-record'),
                    title: this.appContainer.i18n.tr('text.attention'),
                },
            });
        }

        let records = this.datatable.instance.data
            .filter(record => selectedRows.includes(record.id))
            .map(record => record.code)
            .join('<br />');


        this.dialogService
            .open({
                viewModel: ConfirmProcedurePaymentDialog,
                model:     {
                    resource: this.datatable.resource,
                    records:  records,
                    action:   {
                        method:     this.paymentsRepository.create.bind(this.paymentsRepository),
                        parameters: [{ procedures: selectedRows }],
                    },
                },
            })
            .whenClosed(response => {
                if ( ! response.wasCancelled) {
                    this.datatable.instance.reload();
                    this.datatable.instance.selectedRows.splice(0, this.datatable.instance.selectedRows.length);
                    this.datatable.instance.allRowsChecked = false;

                    if (response.output.status === true) {
                        this.masterPage.reloadDatatables();

                        // if `tab` already contained the 'payments' value, nothing would happen, so...
                        this.masterPage.tab = 'requests';
                        setTimeout(() => this.masterPage.tab = 'payments', 500);
                    } else {
                        this.alert = this.alertMessage(response.output.status, response.output.message, response.output.errors);
                    }
                }
            });
    }

    /**
     * Handles the `cancel` action button
     *
     * @param row
     *
     * @returns {*}
     */
    cancelRequest(row) {
        this.dialogService
            .open({
                viewModel: ConfirmProcedureCancellationDialog,
                model: {
                    resource: this.datatable.resource,
                    action:   {
                        method:     this.repository.cancel.bind(this.repository),
                        parameters: [row.id],
                    },
                },
            })
            .whenClosed(response => {
                if ( ! response.wasCancelled) {
                    this.masterPage.reloadDatatables();
                }
            });
    }

}
